<template>
  <div class="composant-infos-generales-festival">
    <p class="titre-fiche">Fiche festival "{{ festival.nom_festival }}"</p>
    <div class="saisie-infos-festival">
      <div class="section">
        <p class="titre-section">Infos générales</p>
        <div class="frame-ligne-infos-festival">
          <p class="label">Nom</p>
          <LabelEditable
            v-model="festivalPrivate.nom_festival"
            @change="updateFestival"
            class="label-editable"
          />
        </div>
        <div class="frame-ligne-infos-festival">
          <p class="label">Date</p>
          <LabelEditable
            format="date"
            @change="updateFestival"
            v-model="festivalPrivate.date_festival"
            class="label-editable"
          />
        </div>
        <div class="frame-ligne-infos-festival">
          <p class="label">Lieu</p>
          <LabelEditable
            v-model="festivalPrivate.lieu_festival"
            @change="updateFestival"
            class="label-editable"
          />
        </div>
        <div class="frame-ligne-infos-festival">
          <p class="label">Statut clôture</p>
          <p class="statut-cloture">{{ statutCloture }}</p>
        </div>
      </div>
      <div class="section">
        <p class="titre-section">Ventes</p>
        <div class="frame-ligne-infos-festival">
          <p class="label">
            Quantite totale emmenée
            <span 
              class="validation infobulle"
              bulle-content="Cette quantité inclut les numéros dans les packs"
            >
              (?)
            </span>
          </p>
          <p class="statut-cloture">
            {{ quantiteTotaleEmmenee }}
          </p>
        </div>
        <div class="frame-ligne-infos-festival">
          <p class="label">
            Quantite totale ramenée
            <span 
              class="validation infobulle"
              bulle-content="Cette quantité inclut les numéros dans les packs"
            >
              (?)
            </span>

          </p>
          <p class="statut-cloture">
            {{ quantiteTotaleRamenee }}
          </p>
        </div>
        <div class="frame-ligne-infos-festival">
          <p class="label">Nombre de ventes</p>
          <p class="statut-cloture">
            {{ nombreVentesTotales }}
          </p>
        </div>
        <!-- <div class="frame-ligne-infos-festival">
          <p class="label">Numéro le mieux vendu</p>
          <p class="statut-cloture">{{ bestSeller }}</p>
        </div> -->
      </div>
      <div class="section">
        <p class="titre-section">Administration</p>
        <div class="frame-ligne-infos-festival">
          <p class="label">Créateur de la fiche festival</p>
          <p class="statut-cloture">{{ createurFiche }}</p>
        </div>
        <!-- <div class="frame-ligne-infos-festival">
          <p class="label">Date création de la fiche</p>
          <p class="statut-cloture">
            {{ dateCreationFiche }}
          </p>
        </div>
        <div class="frame-ligne-infos-festival">
          <p class="label">Date de clôture</p>
          <p class="statut-cloture">{{ dateCloture }}</p>
        </div> -->
      </div>
    </div>
    <div class="frame-cloturer" @click="handleBoutonCloturerDecloturer">
      <p class="bouton-cloturer">{{ labelBoutonCloturer }}</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "ComposantFicheFestival",
  data: () => {
    return {
      festivalPrivate: {
        nom_festival: "",
        date_festival: "",
        lieu_festival: "",
      },
      quantiteTotaleEmmenee: 0,
      quantiteTotaleRamenee: 0,
    };
  },
  props: {
    festival: {
      default: () => ({
        nom_festival: "Inconnu",
        date_festival: "Inconnu",
        lieu_festival: "Inconnu",
      }),
    },
    handleBoutonCloturerDecloturer: {
      type: Function,
      default: () => {
        console.error("Aucune fonction de cloture / decloture fournie");
      },
    },
    updateInfosFestival: {
      type: Function,
      default: () => {
        console.error("Aucune fonction d'update festival fournie");
      },
    },
  },
  model: {
    prop: "festival",
    event: "update",
  },
  computed: {
    ...mapState(["user"]),
    labelBoutonCloturer() {
      return this.festival.cloture_festival == 1 ? "Déclôturer" : "Clôturer";
    },
    bestSeller() {
      return "Inconnu";
    },
    createurFiche() {
      return this.festival.nom_contributeur == undefined
        ? "Inconnu"
        : `${this.festival.prenom_contributeur} ${this.festival.nom_contributeur}`;
    },
    dateCloture() {
      return "Non clôturé";
    },
    dateCreationFiche() {
      return "Inconnue";
    },
    nombreVentesTotales() {
      let total =
        parseInt(this.quantiteTotaleEmmenee) -
        parseInt(this.quantiteTotaleRamenee);
      return isNaN(total) ? " - " : total;
    },
    statutCloture() {
      return this.festival.cloture_festival == "0" ? "Non clôturé" : "Clôturé";
    },
  },
  methods: {
    ...mapActions(["phpPost"]),
    updateFestival() {
      this.$emit("update", { ...this.festival, ...this.festivalPrivate });
    },
    getInfosFestival() {
      this.festivalPrivate = {
        nom_festival: this.festival.nom_festival,
        lieu_festival: this.festival.lieu_festival,
        date_festival: this.festival.date_festival,
      };
      this.phpPost({
        phpFile: "assets/php/statistiquesFestival.php",
        sendObject: {
          id_festival: this.festival.id_festival,
        },
        disableThumbnail: true,
        callBack: (response) => {
          if (response.reqSuccess) {
            this.quantiteTotaleEmmenee = response.statistiques.totalEmmenes;
            this.quantiteTotaleRamenee = response.statistiques.totalRamenes;
            this.festivalPrivate = this.festival;
          }
        },
      });
    },
  },
  watch: {
    festival() {
      this.getInfosFestival();
    },
  },
  mounted: function() {
    this.festivalPrivate = this.festival;
    window.scrollTo(0, 0);
    if (this.festival.id_festival != undefined) this.getInfosFestival();
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/components.scss";
.composant-infos-generales-festival {
  background-color: $couleur-fond-menu-navigation;
  border-radius: 11px;
  padding: 13.5px;
  @media (max-width: $mobile-break-point) {
    padding: 5px;
  }
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.titre-fiche {
  color: $couleur-texte-fonce;
  align-self: stretch;
  text-align: center;
  margin-bottom: 33px;
  @include typo-titre;
}
.saisie-infos-festival {
  margin-bottom: 33px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &:not(:last-of-type) {
    margin-bottom: 34px;
  }

  @media (max-width: $mobile-break-point) {
    width: 100%;
  }
}
.titre-section {
  color: $couleur-texte-fonce;
  align-self: stretch;
  margin-bottom: 10px;
  @include typo-festival;
}
.frame-ligne-infos-festival {
  background-color: $couleur-fond-composant-lieu;
  overflow: visible;
  border-radius: 14px;
  padding: 9px 27px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
  & .infobulle:hover::after{
    left: 280px!important;
    top: -30px!important;
  }
  @media (max-width: $mobile-break-point) {
    width: 100%;
  }
}
.label {
  width: 250px;
  @media (max-width: $mobile-break-point) {
    width: 80%;
  }
  color: $couleur-texte-fonce;
  margin-right: 55px;
  @include typo-festival;
}
.label-editable {
  width: 250px;
  align-self: stretch;
  @media (max-width: $mobile-break-point) {
    width: 100%;
  }
}
.statut-cloture {
  width: 250px;
  @media (max-width: $mobile-break-point) {
    width: 100%;
  }
  color: $couleur-texte-fonce;
  @include typo-festival;
}
.frame-cloturer {
  background-color: $neutral-btn;
  @include btnHover($neutral-btn);
  border-radius: 9px;
  padding: 12px 16px;
  box-shadow: 0 5px 20px 0 $opacity-25;
  display: flex;
  align-items: center;
  justify-content: center;
  // height: 13px;
}
.bouton-cloturer {
  color: $fond-page;
  text-align: center;
  @include typo-medium;
}
</style>
